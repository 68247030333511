import DoucmentView from "../../components/DoucmentView";
export default [
  {
    type: "selection",
    width: 38,
    align: "center",
  },
  {
    title: "Name",
    key: "Name",
    minWidth: 80,
  },
  {
    title: "Award Name",
    key: "AwardName",
    minWidth: 80,
  },
  {
    title: "Nominated By",
    key: "NominatedBy",
    sortable: true,
    minWidth: 80,
  },

  {
    title: "Document Name",
    key: "DocumentName",
    minWidth: 80,
    render: (h, params) => {
      return h(DoucmentView, {
          props: {
              row: params.row
          }
      });
  },
  },
  {
    title: "Month",
    key: "Month",
    minWidth: 80,
  },
  {
    title: "Year",
    key: "Year",
    minWidth: 80,
  },
];
