<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Awards" slot="title" link="/helpContent/StaffAwardList" />
      <FormRow>
        <div class="col-sm-2">
            <FormSelect label="User Type" :items="userTypeList" item-name="name" item-value="id" v-model="filtering.selectedUserTypeId" :onChange="getStaffList"/>
        </div>
        <div class="col-sm-2">
            <FormSelect label="Select Staff" :items="staffList" item-name="Name" item-value="id" v-model="filtering.selectedStaffId"/>
        </div>
        <div class="col-sm-2">
            <FormSelect label="Select Award" :items="awardList" item-name="Name" item-value="id" v-model="filtering.selectedAwardId"/>
        </div>
        <div class="col-sm-2">
          <Year label="Year" v-model="filtering.year" />
        </div>
        <div class="col-sm-2">
          <InputMonth
            label="Month"
            :items="monthsList"
            item-name="NAME"
            item-value="id"
            v-model="filtering.month"
          />
        </div>
        <div class="col-sm-2">
            <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                <Icon type="ios-search" />
            </FormButton>
        </div>
      </FormRow>
      <DataTable
        :actions="true"
        :data="staffAwardList"
        :columns="tableConfig"
        :is-loading="isLoading"
        @on-selection-change="selectChange"
        :pagination="pagination"
        @on-page-change="pageChange"
        @on-sort-change="sortChange"
      >
      <template slot="actionsLeft">
        <TableButton @click="() => add()">
          <img :src="require(`Assets/images/plus-1.png`)" />
          Add
        </TableButton>
        <TableButton @click="() => remove()">
          <img :src="require(`Assets/images/del.png`)" />
          Remove
        </TableButton>
      </template>
    </DataTable>
    <Popup
      title="Information"
      :value="warningModal.visible"
      @close="() => showWarningModal(false)"
    >
      {{ warningModal.message }}
      <div slot="footer">
        <FormButton
          v-if="warningModal.buttons.cancel"
          @click="() => showWarningModal(false)"
          >Cancel
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.confirm"
          type="success"
          @click="confirmDelete"
          >Confirm
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.ok"
          @click="() => showWarningModal(false)"
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>
<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import validateSelectedRows from "Mixins/validateSelectedRows";
import {
  getAllStaff,
  getAllActiveDeactiveStaff,
  getStaffAwardList,
  getAwardList,
  removeStaffAWard,
} from "../api";
import tableConfig from "./table";
import Year from "../../components/Year";
export default {
  name: "List",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    Year,
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  data() {
    return {
      filtering: {
        selectedUserTypeId:null,
        selectedStaffId: null,
        year: new Date().getFullYear(),
        month: new Date().getMonth()+1,
        selectedAwardId: null
      },
      userTypeList:[
          {
              'id': 'All',
              'name': 'All Users'
          },
          {
              'id': 'Active',
              'name': 'Active Users'
          },
          {
              'id': 'Deactive',
              'name': 'Deactive Users'
          }
      ],
      staffList: [],
      staffAwardList: [],
      awardList: [],
      monthsList: [],
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      tableConfig,
      allStaffList: [],
      selectedRows: [],
      showMessage: {
        isVisible: false,
        message: "",
      },
    };
  },
  created() {
    this.getAllList();
  },
  methods: {
    add() {
      this.$router.push({ path: "/staffAward" });
    },
    remove() {
      if (this.validateSelectedLength(1)) {
        if (this.deleteSelectedValue()) {
        }
      }
    },
    confirmDelete() {
      this.showWarningModal(false);
      const data = {
        id: this.selectedRows[0].id,
      };
      removeStaffAWard(data).then((res) => {
        if (res.data[0].status > 1) {
          this.showWarningModal(true);
          this.warningModal.buttons.ok = true;
          this.warningModal.buttons.cancel = false;
          this.warningModal.buttons.confirm = false;
          this.warningModal.message = res.data[0].message;
          this.selectedRows = [];
        } else {
          this.selectedRows = [];
          this.warningModal.buttons.ok = true;
          this.warningModal.buttons.cancel = false;
          this.warningModal.buttons.confirm = false;
          this.showMessage.isVisible = true;
          this.showMessage.message = res.data[0].message;
          this.getDataByFilter();
        }
      });
    },
    async getAllList() {
      //Changes by Mahima on 18th July 2023: Fixed JIRA bug, NEIN-54
      this.filtering.selectedUserTypeId= "Active";
      this.getStaffList();
      getAwardList().then((res) => {
        this.awardList = res.data;
      });
      setTimeout(() => {
        this.getDataByFilter();
      }, 2000);
    },
    async getStaffList(ele) {
      if (ele != undefined) {
          this.filtering.selectedUserTypeId=ele;
      }
      if(this.filtering.selectedUserTypeId){
          if (this.filtering.selectedUserTypeId === "All") {
              getAllActiveDeactiveStaff().then((res)=>{
                  this.staffList=res.data
              });
          } else if (this.filtering.selectedUserTypeId === "Active") {
              getAllStaff().then((res)=>{
                  this.staffList=res.data
              });
          } else if (this.filtering.selectedUserTypeId === "Deactive") {
              await getAllActiveDeactiveStaff().then((res)=>{
                  this.allStaffList=res.data;
              });
              
              await getAllStaff().then((res)=>{
                  this.activeStaffList = res.data;
              });

              let activeselectedStaffIds = [];
              for (let j = 0; j < this.activeStaffList.length; j++) {
                  activeselectedStaffIds.push(this.activeStaffList[j].id);
              }

              this.staffList = [];
              this.allStaffList.forEach((item) => {
                  if (activeselectedStaffIds.indexOf(item.id) == -1) {
                      this.staffList.push(item);
                  }
              });
          }
      }
    },
    search() {
      this.pagination.page = 1;
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },
    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    getDataByFilter() {
      setTimeout(() => {
        this.showLoader();
        const { key, order } = this.sorting;
        const { perPage, page } = this.pagination;

        const filters = {};
        if (this.filtering.year) {
            filters.year = this.filtering.year;
        }
        if (this.filtering.month) {
            filters.month = this.filtering.month;
        }
        if (this.filtering.selectedStaffId) {
            filters.staffId = this.filtering.selectedStaffId;
        }
        if (this.filtering.selectedAwardId) {
            filters.awardId = this.filtering.selectedAwardId;
        }
        const data = {
          filterjson: {
            filter: [filters],
            sort: [{ key, order }],
            paging: [
              {
                startIndex: page,
                pagesize: perPage,
              },
            ],
          },
        };
        getStaffAwardList(data)
          .then(this.handleResponse)
          .catch(this.handleError);
      }, 50);
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    }, 
    handleResponse(response) {
      this.hideLoader();
      this.staffAwardList = response.data;
      this.pagination.total = response.NumberOfRecords;
    },
  },
};
</script>

<style lang="scss" module>
.popupRow {
  padding: 5px 0 15px;
}
.pagination {
  margin: 10px 0 0;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  line-height: 26px;
}

.paginationText {
  margin: 0 20px;
}

.paginationButton {
  cursor: pointer;
  width: 26px;
  text-align: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 200ms ease;
}
.paginationButton:hover {
  background: #57a3f3;
  color: #fff;
  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.filter {
  margin: -10px 0 10px;
}

.filterItem {
  display: inline-block;
  margin: 0 15px 15px 0;
}

.filterItemCheckbox {
  flex-basis: auto;
  align-self: flex-start;
  margin-top: 13px;
}
</style>
